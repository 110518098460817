import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { CtaBannerEntry } from "frontend/contentful/schema/blocks";
import { createCtaProps } from "./cta.props";
import dynamic from "next/dynamic";
import { createCtaCardProps } from "./cta-card.props";

interface CtaBannerOptions {
  headerAbove?: boolean;
}

const Cta = dynamic(() =>
  import("design-system/components/blocks/cta/cta").then((e) => ({
    default: e.Cta,
  })),
);
const CtaCard = dynamic(() =>
  import("design-system/components/blocks/cta-card/cta-card").then((e) => ({
    default: e.CtaCard,
  })),
);

export const ContentfulCtaBanner = createContentfulComponent<
  CtaBannerEntry,
  CtaBannerOptions
>(({ entry, options }) => {
  const headerAbove = options?.headerAbove ?? false;

  if (entry.fields.style === "Large Text") {
    const props = createCtaProps(entry);
    if (props) {
      props.HeadingLevel = headerAbove ? "h3" : "h2";
    }

    return <Cta {...props} />;
  }

  const props = createCtaCardProps(entry);
  if (props) {
    props.HeadingLevel = headerAbove ? "h3" : "h2";
  }
  return <CtaCard {...props} />;
});
