import { CtaCardProps } from "design-system/components/blocks/cta-card/cta-card";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { CtaBannerEntry } from "frontend/contentful/schema/blocks";
import { RenderContentfulRichText } from "frontend/contentful/components/utils/render-contentful-rich-text";
import { createCtaProps } from "./cta.props";

export const createCtaCardProps = createContentfulProps<
  CtaBannerEntry,
  CtaCardProps
>(({ entry, createEditAttributes }) => {
  const ctaProps = createCtaProps(entry);

  const props: CtaCardProps = {
    ...ctaProps,

    editAttributes: {
      ...ctaProps?.editAttributes,
    },
  };

  if (entry.fields.overline) {
    props.overline = (
      <RenderContentfulRichText document={entry.fields.overline} />
    );
    if (props.editAttributes) {
      props.editAttributes.overline = createEditAttributes({
        entry,
        fieldId: "overline",
      });
    }
  }

  return props;
});
