import { CtaProps } from "design-system/components/blocks/cta/cta";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { CtaBannerEntry } from "frontend/contentful/schema/blocks";
import { RenderContentfulRichText } from "frontend/contentful/components/utils/render-contentful-rich-text";
import { createMediaAssetProps } from "frontend/contentful/components/primitives/media-asset.props";
import { useContentful } from "frontend/hooks/use-contentful";

export const createCtaProps = createContentfulProps<CtaBannerEntry, CtaProps>(
  ({ entry, createEditAttributes }) => {
    const { getEntryUrl, getEntries, getEntry } = useContentful();

    const props: CtaProps = {
      title: <RenderContentfulRichText document={entry.fields.title} />,
      subtitle: <RenderContentfulRichText document={entry.fields.subtitle} />,
      align: entry.fields.alignment!,
      theme: entry.fields.theme!,
      mediaAlignment: entry.fields.mediaAlignment,

      editAttributes: {
        block: createEditAttributes({ entry }),
        title: createEditAttributes({ entry, fieldId: "title" }),
        subtitle: createEditAttributes({ entry, fieldId: "subtitle" }),
      },
    };

    getEntries(entry.fields.media)?.forEach((entry) => {
      const asset = createMediaAssetProps(entry);
      if (asset) {
        if (!props.media) props.media = [asset];
        else props.media.push(asset);
      }
    });

    if (entry.fields.links) {
      props.ctaList = {
        items: getEntries(entry.fields.links).map((link) => ({
          children: link.fields.text,
          href: getEntryUrl(link),
          editAttributes: createEditAttributes({
            entry: link,
            fieldId: "text",
          }),
        })),
      };
    }

    const button = getEntry(entry.fields.button);

    if (button) {
      props.primaryCta = {
        type: "primary-button",
        href: getEntryUrl(entry.fields.button),
        "aria-label": button.fields.ariaLabel,
        children: button.fields.text,
        editAttributes: createEditAttributes({
          entry: button,
          fieldId: "text",
        }),
      };

      //Up to 4 Links in a link list. If the Button field is not empty, only 1 Link can be displayed.
      if (props.ctaList?.items?.[0]) {
        props.secondaryCta = {
          editAttributes: props.ctaList.items[0]?.editAttributes,
          children: props.ctaList.items[0]?.children,
          href: props.ctaList.items[0]?.href,
          "aria-label": props.ctaList.items[0]?.["aria-label"],
        };

        props.ctaList = undefined;
      }
    }

    return props;
  },
);
